import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosRequestConfiguration } from './config';
import cookie from 'react-cookies';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token');
    return `Bearer ${token}`;
};
const getSchema = () => {
    return cookie.load('SCH_ID');
};

const initialization = (configs: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(configs);
    axiosInstance.interceptors.request.use(async (request) => {
        if (request.url?.includes('password/change')) {
            request.headers.Authorization = tokenFunction();
            request.headers.SCH_ID = 'EWMG';
            return request;
        }
        if (request.url?.includes('user/visibility')) {
            request.headers.Authorization = tokenFunction();
            return request;
        }

        if (!request.url?.includes('login') && !request.url?.includes('verify') && !request.url?.includes('password/forgotten')) {
            request.headers.Authorization = tokenFunction();
            request.headers.SCH_ID = getSchema();

            if (
                request.url?.includes('partner-managements/partners') ||
                request.url?.includes('common-data-managements/product-groups') ||
                request.url?.includes('common-data-managements/product-brands') ||
                request.url?.includes('shop-managements/shops')
            ) {
                request.headers['X-API-VERSION'] = 'NO_PAGING';
            }

            return request;
        }
        return request;
    });
    return axiosInstance;
};

const axiosInstance = initialization(axiosRequestConfiguration);

export default axiosInstance;
